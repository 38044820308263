/* istanbul ignore file */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PWAPrompt from 'react-ios-pwa-prompt';
import 'normalize.css/normalize.css';
import { locale } from '@churchms/shared';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Router from './router';
// contexts
import { ModalProvider } from './contexts/ModalContext';
import { ErrorBoundary } from './components';

// styles
import './styles/index.scss';
import { AvatarDefinitions } from './components/Avatar';

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

const FallbackComponent = () => <div>{locale.ERROR_BOUNDARY.APP._}</div>;

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ModalProvider>
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <Router />
          <AvatarDefinitions />
        </ErrorBoundary>
      </ModalProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      <PWAPrompt
        copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen."
        permanentlyHideOnDismiss={false}
        delay={5000}
        timesToShow={3}
      />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
