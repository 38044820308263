import { useCanShowBlog } from '../../hooks';
import {
  usePrefetchDirectory,
  usePrefetchBlog,
  usePrefetchChurch,
  usePrefetchListings,
} from '../../hooks/prefetch';
import { ReactComponent as FeedSVG } from '../../icons/article.svg';
import { ReactComponent as DirectorySVG } from '../../icons/contact_page.svg';
import { ReactComponent as HomeSVG } from '../../icons/home_filled.svg';
import { ReactComponent as MarketplaceSVG } from '../../icons/storefront.svg';
import NavItem from './NavItem';

const UserNav = () => {
  const prefetchChurch = usePrefetchChurch();
  const prefetchDirectory = usePrefetchDirectory();
  const prefetchBlog = usePrefetchBlog();
  const prefetchListings = usePrefetchListings();
  const canShowBlog = useCanShowBlog();

  return (
    <>
      <NavItem
        to="/"
        Icon={HomeSVG}
        label="Home"
        exact
        onMouseEnter={prefetchChurch}
      />
      {canShowBlog && (
        <NavItem
          to="/blog"
          Icon={FeedSVG}
          label="Blog"
          onMouseEnter={prefetchBlog}
        />
      )}
      <NavItem
        to="/directory"
        Icon={DirectorySVG}
        label="Directory"
        onMouseEnter={prefetchDirectory}
      />
      <NavItem
        to="/market"
        Icon={MarketplaceSVG}
        label="Market"
        onMouseEnter={prefetchListings}
      />
    </>
  );
};

export default UserNav;
