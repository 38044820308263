import { Link } from 'react-router-dom';
import { objectToQueryParams } from '@churchms/shared';
import styles from './styles.module.scss';

const Preview = ({
  title,
  img,
  guid,
  contentSnippet,
  blogTitle,
  creator,
  isLast,
  ...props
}) => {
  const to = `/blog/article${objectToQueryParams({
    guid: encodeURIComponent(guid),
  })}`;

  return (
    <Link
      className={`${styles.preview}${isLast ? ` ${styles.lastArticle}` : ''}`}
      to={to}
    >
      <div className={styles.container}>
        {img && (
          <div
            className={styles.featuredImage}
            style={{ background: `url(${img}) center/cover` }}
          />
        )}
        <div
          className={styles.text}
          style={{ position: img ? 'absolute' : '' }}
        >
          <h2 className={styles.articleTitle}>{title}</h2>
          <p>
            <b>{blogTitle}</b>
            {creator && ' by '}
            {creator && <b>{creator}</b>}
          </p>
          <p className={styles.contentSnippet}>{contentSnippet}</p>
        </div>
      </div>
    </Link>
  );
};

export default Preview;
