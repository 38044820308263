import { useEffect, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { locale } from '@churchms/shared';
import styles from './styles.module.scss';
import { ReactComponent as LinkSVG } from '../../icons/link.svg';
import Preview from './Preview';

const Article = (props) => {
  const {
    isoDate,
    link,
    title,
    creator,
    categories,
    showFullContent = false,
    blogTitle,
    blogUrl,
  } = props;

  const [img, setImg] = useState('');
  const d = new Date(isoDate);
  const dateString = isoDate
    ? d.toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : '';
  const sanitizedHTML = DOMPurify.sanitize(
    props[showFullContent ? 'content:encoded' : 'content:encodedSnippet']
  );
  const maxLength = 500;
  const truncated =
    sanitizedHTML.length <= maxLength || showFullContent
      ? sanitizedHTML
      : sanitizedHTML.slice(0, maxLength) + '...';

  useEffect(() => {
    const parser = new DOMParser();
    const sanitized = DOMPurify.sanitize(props['content:encoded']);
    const parsed = parser.parseFromString(sanitized, 'text/html');
    const firstImg = parsed.querySelector('img');

    if (firstImg && firstImg.src) {
      setImg(firstImg.src);
    }
  }, [props]);

  // const to = `/blog/article${objectToQueryParams({
  //   guid: encodeURIComponent(guid),
  // })}`;

  if (!showFullContent) {
    return <Preview {...props} img={img} />;
  }

  return (
    <article className={styles.article}>
      <h2 className={styles.articleTitle}>
        {/* {showFullContent ? title : <Link to={to}>{title}</Link>} */}
        {title}
      </h2>
      <section className={styles.byline}>
        <address>
          Blog:{' '}
          <a href={blogUrl} target="_blank" rel="noopener noreferrer">
            {blogTitle}
          </a>
        </address>
        <address>Author: {creator}</address>
        {dateString && <div>{dateString}</div>}
      </section>
      {categories && (
        <section className={styles.categories}>
          {categories.map((c) => (
            <span key={c}>{c}</span>
          ))}
        </section>
      )}
      {/* {img && !showFullContent && (
        <img className={styles.featuredImage} src={img} alt={title} />
      )} */}
      <section
        className={showFullContent ? '' : styles.snippetContent}
        dangerouslySetInnerHTML={{ __html: truncated }}
      />
      <footer>
        <p className={styles.footerLink}>
          {/* {showFullContent ? (
            <a href={link} target="_blank" rel="noopener noreferrer">
              <span>{locale.COMMON.VISIT_WEBSITE}</span>
              <LinkSVG />
            </a>
          ) : (
            (sanitizedHTML.length > maxLength || !sanitizedHTML) && (
              <Link to={to}>
                <span>{locale.COMMON.READ_MORE}</span>
                <ReadMoreSVG />
              </Link>
            )
          )} */}
          <a href={link} target="_blank" rel="noopener noreferrer">
            <span>{locale.COMMON.VISIT_WEBSITE}</span>
            <LinkSVG />
          </a>
        </p>
      </footer>
    </article>
  );
};

export default Article;
