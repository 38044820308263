/* istanbul ignore file */

export const login = () => ['login'];

export const forgotPasswordKey = (email) => ['forgot-password', email];

export const churches = () => ['churches'];

export const directory = () => ['directory'];

export const issues = (search) => ['issues', search];

export const labels = () => ['labels'];

export const profilePicture = (id) => ['profile-picture', id];

export const church = (role) => ['church', role];

export const blog = (feedUrls = []) => ['blog', ...feedUrls].filter(Boolean);

export const feed = (url) => ['feed', url];

export const listings = () => ['listings'];
