/* istanbul ignore file */

module.exports = {
  HTTP: {
    ERROR: {
      LOGIN: 'Unable to login. Please try again later.',
      LOGOUT: 'Unable to logout. Please try again later.',
      FORGOT_PASSWORD:
        'Unable to reset passwords at this time. Please try again later.',
      RECOVER_ACCOUNT: 'Unable to reset password. Please try again later.',
      PASSWORD_RESET_HAS_EXPIRED:
        'This password reset request has expired. Please request a new one.',
      INCORRECT_PASSWORD: 'Incorrect username/password',
      INVALID_TOKEN: 'User is not authenticated',
      UNAUTHORIZED: 'User is unauthorized',
      CHURCHES: 'Unable to get churches. Please try again later.',
      CHURCH_CREATE: 'Unable to create church. Please try again later.',
      CHURCH_DELETE: 'Unable to delete church. Please try again later.',
      USERS: 'Unable to get users. Please try again later.',
      FAMILY_CREATE: 'Unable to create a family. Please try again later.',
      FAMILY_DELETE: 'Unable to delete family. Please try again later.',
      FAMILY_GET: 'Unable to get families. Please try again later.',
      FAMILY_DOES_NOT_EXIST: 'Family not found.',
      EMAIL_EXISTS: 'Email already in use.',
      USER_CREATE: 'Unable to create user. Please try again later.',
      USER_DELETE: 'Unable to delete user. Please try again later.',
      NAME_EXISTS: 'There is a user with this name already.',
      USER_NOT_FOUND: 'User not found.',
      UPDATE_USER: 'Unable to update user.',
      EDIT_PHONE: 'Unable to edit phone number.',
      WRONG_PHONE_NUMBER_PATTERN: 'Phone number does not match pattern',
      DUPLICATE_PHONE_NUMBER: 'Phone number already exists',
      PHONE_DOES_NOT_EXIST: 'Phone number not found',
      DELETE_PHONE: 'Unable to remove phone number',
      CONFIRM_EMAIL_CHANGE: 'Unable to change email',
      EDIT_USER_ADDRESS: 'Unable to edit user address',
      DELETE_USER_ADDRESS: 'Unable to delete user address',
      DUPLICATE_USER_ADDRESS: 'This address already exists',
      ADDRESS_DOES_NOT_EXIST: 'Address not found',
      HIDE_USER: 'Unable to hide user',
      SHOW_USER: 'Unable to show user',
      CHANGE_USER_NAME: 'Unable to change name',
      CREATE_ISSUE: 'Unable to create issue',
      GET_ISSUES: 'Unable to get issues',
      CHANGE_FAMILY_NAME: 'Unable to change family name',
      GET_LABELS: 'Unable to get labels',
      CHANGE_HEAD_OF_HOUSEHOLD: 'Unable to change head of household',
      CHANGE_ROLE: 'Unable to change role',
      MOVE_USER: 'Unable to move user',
      FILE_TOO_LARGE:
        'File is too large. Please select a file smaller than {0}.',
      EMAIL_CHANGE_REQUEST_HAS_EXPIRED:
        'This email change request has expired. Please request a new one.',
      PASSWORD_TOO_SHORT:
        'This password is too short. Please use a longer password.',
      CHANGE_PASSWORD: 'Unable to change password.',
      ADD_PROFILE_PICTURE: 'Unable to add profile picture',
      DELETE_PROFILE_PICTURE: 'Unable to delete profile picture',
      GET_PROFILE_PICTURE: 'Not found',
      GET_CHURCH: 'Unable to get church',
      CHURCH_NOT_FOUND: 'Church not found',
      EDIT_CHURCH: 'Unable to edit church',
      GET_RSS: 'Unable to get posts',
      INVALID_URL: 'URL is not valid',
      TOKEN: 'Unable to get token',
      LISTING_CREATE: 'Unable to create listing',
      LISTING_DELETE: 'Unable to delete listing',
      LISTING_DOES_NOT_EXIST: 'Listing does not exist',
      LISTINGS: 'Unable to get listings',
      LISTING_EDIT: 'Unable to edit listing',
    },
    SUCCESS: {
      FORGOT_PASSWORD:
        'A confirmation email has been sent to {0}. Please check your inbox within the next 12 hours to complete the account recovery process.',
      EMAIL_SET: 'User updated successfully',
      EMAIL_CHANGE_REQUEST_SENT: 'Email change request sent successfully',
    },
  },
  COMMON: {
    SAVE: 'Save',
    CREATE: 'Create',
    SUBMIT: 'Submit',
    CANCEL: 'Cancel',
    DONE: 'Done',
    CHURCH: 'Church',
    CHURCH_NAME: 'Church name',
    EMAIL: 'Email',
    ADMIN_USER: 'Admin user',
    WEBSITE: 'Website',
    ADDRESS: 'Address',
    CONTACT: 'Contact',
    PHONE: 'Phone',
    STATE: 'State',
    CITY: 'City',
    STREET1: 'Street 1',
    STREET2: 'Street 2',
    ZIP_CODE: 'Zip Code',
    COUNTRY: 'Country',
    SURNAME: 'Surname',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    CREATE_ACCOUNT: 'Create account',
    RESET_PASSWORD: 'Reset password',
    RECOVER_ACCOUNT: 'Recover account',
    HEAD_OF_HOUSEHOLD: 'Head of household',
    FAMILY: 'Family',
    FAMILIES: 'Families',
    MEMBERS: 'Members',
    FAMILY_LABEL_WITH_NAME: '{0} family',
    CREATE_USER: 'Create user',
    CONFIRM: 'Confirm',
    OK: 'Ok',
    LABEL: 'Label',
    PHONE_NUMBER: 'Phone Number',
    BIRTHDAY: 'Birthday',
    ADULTS: 'Adults',
    CHILDREN: 'Children',
    SEX: 'Sex',
    TITLE: 'Title',
    TYPE: 'Type',
    BODY: 'Body',
    IMAGE: 'Image',
    ROLE: 'Role',
    LOGOUT: 'Logout',
    SEARCH: 'Search',
    COPY: 'Copy',
    SMS: 'SMS',
    FOUND: 'Found',
    ADDED: 'Added',
    REMOVED: 'Removed',
    RESET: 'Reset',
    APPLY: 'Apply',
    EMAIL_USERS: 'Email {0} users',
    ARE_YOU_SURE: 'Are you sure?',
    YES: 'Yes',
    NO: 'No',
    MODE: 'Mode',
    URL: 'URL',
    VISIT_WEBSITE: 'Visit website',
    PRICE: 'Price',
    KEYWORDS: 'Keywords',
  },
  MODALS: {
    CREATE_CHURCH: {
      _: 'Create church',
    },
    EDIT_CHURCH: {
      _: 'Edit church',
    },
    DELETE_CHURCH: {
      _: 'Delete church',
      CONFIRM:
        'This will permanently delete {0} and all its associated families and users. Are you sure?',
    },
    ERROR: {
      _: 'Error',
      BODY: 'Sorry! Something went wrong. Please try again later.',
    },
    ADD_PHONE: {
      _: 'Add phone number',
    },
    ADD_EMAIL: {
      _: 'Invite user',
      DESCRIPTION:
        'This will send them an invitation email where they can reset their password and login.',
    },
    CHANGE_EMAIL: {
      _: 'Change email',
      DESCRIPTION:
        'This will send a confirmation to your current email. You must click that link before the change will go into effect.',
    },
    CONFIRM_CHANGE_EMAIL: {
      _: 'Changing email...',
      SUCCESS: 'Email changed!',
    },
    EDIT_PHONE: {
      _: 'Edit phone number',
    },
    DELETE_PHONE: {
      _: 'Delete phone',
      CONFIRM:
        'This will permanently delete the phone number: {0}. Are you sure?',
    },
    CREATE_FAMILY: {
      _: 'Create family',
      LEGEND: 'Head of household',
    },
    CREATE_USER: {
      _: 'Create user',
      LEGEND: 'New member of {0} family',
      IS_ADULT: 'Is an adult',
      NO_EMAIL:
        'Users created without an email will not have app access (useful for child accounts).',
      HIDE_USER_LABEL: 'Hide this user',
      HIDE_USER_WARNING:
        "Hidden users will not have app access (with or without email). Only admins can see hidden users (useful for keeping track of regular attenders that aren't members yet).",
    },
    DELETE_USER: {
      _: 'Delete user',
      CONFIRM: "This will permanently delete {0}'s account. Are you sure?",
    },
    DELETE_FAMILY: {
      _: 'Delete family',
      CONFIRM:
        'This will permanently delete the {0} family and all of its member(s). Are you sure?',
    },
    EDIT_BIRTHDAY: {
      _: 'Edit birthday',
    },
    DELETE_BIRTHDAY: {
      _: 'Remove birthday',
      CONFIRM: "This will remove {0}'s birthday. Are you sure?",
    },
    CREATE_ADDRESS: {
      _: 'Add address',
    },
    EDIT_ADDRESS: {
      _: 'Edit address',
    },
    DELETE_ADDRESS: {
      _: 'Remove address',
      CONFIRM: 'The following address will be deleted. Are you sure?',
    },
    HIDE_USER: {
      _: 'Hide user',
      CONFIRM:
        'This will hide {0} from view by other members. They will not be able to log in. Admins will still be able to see them. Are you sure?',
    },
    SHOW_USER: {
      _: 'Show user',
      CONFIRM:
        "This will make {0}'s account visible. If they have an email set, they will also be sent a new user invitation email. Are you sure?",
    },
    CHANGE_NAME: {
      _: 'Change name',
    },
    FEEDBACK: {
      _: 'Feedback',
      BODY_PLACEHOLDER: 'Leave a comment',
      SIMILAR_ISSUE_SINGLE: 'similar issue',
      SIMILAR_ISSUE_MULTIPLE: 'similar issues',
    },
    CHANGE_FAMILY_NAME: {
      _: 'Change family name',
      CHANGE_MEMBERS_ALSO: 'Update members also',
    },
    CHANGE_HEAD_OF_HOUSEHOLD: {
      _: 'Change head of household',
      SHORT: 'Change family head',
      LABEL: 'New head of household',
      DESCRIPTION: 'Heads of households can edit members in their family.',
    },
    CHANGE_ROLE: {
      _: 'Change role',
    },
    MOVE_USER: {
      _: 'Move user',
      NEW_FAMILY: 'New family',
      UPDATE_LAST_NAME: 'Update last name',
    },
    DIRECTORY_SEARCH: {
      _: 'Search directory',
      SEARCH_TIP:
        'Tip: use keywords like "men", "women", "age>30", or "age<24"',
      INCLUDED: '{0} included',
      EXCLUDED: '{0} excluded',
      RESET: 'Reset?',
      COUNT_SINGLE: '1 result',
      COUNT_MULTI: '{0} results',
      OPTION: {
        EMAIL: 'email',
        TEXT_MESSAGE: 'text message',
      },
    },
    CHANGE_PASSWORD: {
      _: 'Change password',
      MUST_MATCH: 'Passwords must match',
      OLD_PASSWORD: 'Old password',
      NEW_PASSWORD: 'New password',
      CONFIRM_NEW_PASSWORD: 'Confirm new password',
    },
    ADD_PROFILE_PICTURE: {
      _: 'Add profile picture',
      PROFILE_PICTURE: 'Profile picture',
      SCALE: 'Scale',
    },
    DELETE_PROFILE_PICTURE: {
      _: 'Delete profile picture',
    },
    CHANGE_BLOG_FEEDS: {
      _: 'Change blogs',
      FEED_RESULTS: '{0} feed{1} found:',
      SELECTED_COUNT: '{0} feed{1} selected:',
    },
    SHARE_DIRECTORY: {
      _: 'Share...',
    },
    CREATE_LISTING: {
      _: 'Create listing',
    },
    DELETE_LISTING: {
      _: 'Delete listing',
      CONFIRM: 'This will permanently delete this listing. Are you sure?',
    },
    EDIT_LISTING: {
      _: 'Edit listing',
    },
    MARKET_SEARCH: {
      _: 'Search market',
      MY_LISTINGS: 'my listings',
    },
  },
  USER_DETAILS: {
    ADD_PHONE: 'Add phone number',
    ADD_BIRTHDAY: 'Add birthday',
    ADD_EMAIL: 'Invite user',
    CREATE_ADDRESS: 'Add address',
    PHONE_NUMBERS: 'Phone numbers',
    ADDRESSES: 'Addresses',
    OTHER: 'Other details',
  },
  ERROR_BOUNDARY: {
    APP: {
      _: 'Oh no! Something went wrong. Please try again later.',
    },
    PAGE: {
      _: 'Something is wrong with this page. Please try again later.',
    },
  },
  MORE: {
    EDIT_PROFILE: 'Edit profile',
    PRINT_DIRECTORY: 'Print directory',
  },
  BLOG: {
    NO_BLOG_1:
      'You have not subscribed to any blog sources yet. Click on the gear in the bottom right to add blogs to your feed.',
    NO_BLOG_2:
      'If you choose to leave this page blank, only Admins will see this page.',
  },
  MARKETING: {
    CATEGORY: 'Christian Nationalism',
    LAUNCH_APP: 'Launch app',
    DESCRIPTION: 'Connect with members of your church.',
    SHOW_DETAILS: 'Show details',
    HIDE_DETAILS: 'Hide details',
    FEATURES: 'Features',
    TAGS: {
      CHRISTIAN: 'Christian',
      BASED: 'Based',
      WOKE: 'Woke',
      BIG_TECH: 'Big Tech',
    },
  },
};
